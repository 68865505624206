import { getTranslatedErrorMessage } from './translateCodeErrors';

const getErrorMessage = (error) => {
  if (typeof error === 'string') {
    return error;
  }

  switch (true) {
    case !!error?.response?.data?.code:
      return getTranslatedErrorMessage(error.response.data.code);
    case !!error?.response?.data?.message:
      return error.response.data.message;
    case !!error?.message:
      return error.message;
    default:
      return 'Ocurrió algún error.';
  }
};

export const handleError = (error) => {
  return getErrorMessage(error);
};
