const errorsCodes = [
  {
    "message": "The person is already registered on the black list",
    "translation": "La persona ya se encuentra registrada en la lista negra",
    "code": "ERR_PERSON_ALREADY_REGISTERED_BLACK_LIST"
  },
  {
    "message": "BlackList user not found",
    "translation": "Usuario de la lista negra no encontrado",
    "code": "ERR_BLACKLIST_USER_NOT_FOUND"
  },
  {
    "message": "Error when uploading people in blacklist",
    "translation": "Error al subir personas en la lista negra",
    "code": "ERR_UPLOADING_BLACKLIST"
  },
  {
    "message": "Excel has invalid headers",
    "translation": "Excel tiene encabezados inválidos",
    "code": "ERR_EXCEL_INVALID_HEADERS"
  },
  {
    "message": "Excel has more than ${limit} rows",
    "translation": "Excel tiene más del límite de filas permitido",
    "code": "ERR_EXCEL_EXCEEDS_MAXIMUM_ROWS"
  },
  {
    "message": "Excel has duplicate rows",
    "translation": "Excel tiene filas duplicadas",
    "code": "ERR_BLACKLIST_EXCEL_HAS_DUPLICATES"
  },
  {
    "message": "Database",
    "translation": "Error de base de datos",
    "code": "ERR_DATABASE"
  },
  {
    "message": "The candidate is already registered",
    "translation": "El candidato ya se encuentra registrado",
    "code": "ERR_CANDIDATE_ALREADY_REGISTERED"
  },
  {
    "message": "Postulation not allowed",
    "translation": "Postulación no permitida",
    "code": "ERR_CANDIDATE_POSTULATION_NOT_ALLOWED"
  },
  {
    "message": "Candidate not allowed",
    "translation": "Candidato no permitido",
    "code": "ERR_CANDIDATE_PROCESS_NOT_ALLOWED"
  },
  {
    "message": "The candidate cellphone is invalid",
    "translation": "El celular del candidato no es válido",
    "code": "ERR_INVALID_CANDIDATE_CELLPHONE"
  },
  {
    "message": "Candidate not found",
    "translation": "Candidato no encontrado",
    "code": "ERR_CANDIDATE_NOT_FOUND"
  },
  {
    "message": "Postulation not allowed",
    "translation": "Postulación no permitida",
    "code": "ERR_POSTULATION_NOT_ALLOWED"
  },
  {
    "message": "Postulation not found",
    "translation": "Postulación no encontrada",
    "code": "ERR_POSTULATION_NOT_FOUND"
  },
  {
    "message": "Workload not found",
    "translation": "Carga laboral no encontrada",
    "code": "ERR_WORKLOAD_NOT_FOUND"
  },
  {
    "message": "Workload not allowed",
    "translation": "Carga laboral no permitida",
    "code": "ERR_WORKLOAD_NOT_ALLOWED"
  },
  {
    "message": "Process not found",
    "translation": "Proceso no encontrado",
    "code": "ERR_PROCESS_NOT_FOUND"
  },
  {
    "message": "Process is active",
    "translation": "El Proceso está activo",
    "code": "ERR_PROCESS_IS_ACTIVE"
  },
  {
    "message": "Postulation already finalized",
    "translation": "La postulación ya se encuentra finalizada",
    "code": "ERR_POSTULATION_ALREADY_FINALIZED"
  },
  {
    "message": "Process is not active",
    "translation": "El Proceso no está activo",
    "code": "ERR_PROCESS_NOT_ACTIVE"
  },
  {
    "message": "Vacancy not found",
    "translation": "Vacante no encontrada",
    "code": "ERR_VACANCY_NOT_FOUND"
  },
  {
    "message": "Vacancy is not active",
    "translation": "La Vacante no está activa",
    "code": "ERR_VACANCY_NOT_ACTIVE"
  },
  {
    "message": "The IGC to migrate is the same",
    "translation": "El IGC a migrar es el mismo",
    "code": "ERR_POSTULATION_SAME_IGC"
  },
  {
    "message": "Candidate is in blacklist",
    "translation": "Candidato se encuentra en lista negra",
    "code": "ERR_CANDIDATE_IN_BLACKLIST"
  },
  {
    "message": "Candidate is already working on konecta",
    "translation": "Candidato ya se encuentra trabajando en konecta",
    "code": "ERR_CANDIDATE_ALREADY_WORKING_KONECTA"
  },
  {
    "message": "Error when uploading postulations",
    "translation": "Error al subir postulaciones",
    "code": "ERR_UPLOADING_POSTULATIONS"
  },
  {
    "message": "The Postulation has an open process in another IGC",
    "translation": "La Postulación tiene un proceso abierto en otro IGC",
    "code": "ERR_POSTULATION_HAS_OPEN_PROCESS_ANOTHER_IGC"
  },
  {
    "message": "Postulation status not allowed",
    "translation": "Estado de postulación no permitido",
    "code": "ERR_POSTULATION_STATUS_NOT_ALLOWED"
  },
  {
    "message": "Reason is required",
    "translation": "Se requiere motivo",
    "code": "ERR_REASON_REQUIRED"
  },
  {
    "message": "Reason rejection is required",
    "translation": "Se requiere motivo de rechazo",
    "code": "ERR_REASON_REJECTION_REQUIRED"
  },
  {
    "message": "Comment is required",
    "translation": "Se requiere comentario",
    "code": "ERR_COMMENT_REQUIRED"
  },
  {
    "message": "Candidate data is incomplete",
    "translation": "Datos del candidato están incompletos",
    "code": "ERR_POSTULATION_CANDIDATE_DATA_INCOMPLETE"
  },
  {
    "message": "Candidate not found in evaluar",
    "translation": "Candidato no encontrado en evaluar",
    "code": "ERR_EVALUAR_CANDIDATE_NOT_FOUND"
  },
  {
    "message": "Postulation not allowed",
    "translation": "Postulación no permitida",
    "code": "ERR_POSTULATION_PROCESS_NOT_ALLOWED"
  },
  {
    "message": "The candidate dit not finished his tests",
    "translation": "El candidato no finalizó sus pruebas",
    "code": "ERR_CANDIDATE_DID_NOT_FINISH_TESTS"
  },
  {
    "message": "Postulation processStatus has already finished",
    "translation": "El estado del proceso de postulación ya ha finalizado",
    "code": "ERR_POSTULATION_PROCESS_STATUS_HAS_FINISHED"
  },
  {
    "message": "Postulation status cannot be the same as the current one",
    "translation": "El estado de la postulación no puede ser igual al actual",
    "code": "ERR_POSTULATION_STATUS_SAME"
  },
  {
    "message": "Postulation reason rejection not found",
    "translation": "Motivo de rechazo de postulación no encontrado",
    "code": "ERR_POSTULATION_REASON_NOT_FOUND"
  },
  {
    "message": "Postulation status not found",
    "translation": "Estado de postulación no encontrado",
    "code": "ERR_POSTULATION_STATUS_NOT_FOUND"
  },
  {
    "message": "Error de evaluar",
    "translation": "Error de evaluar",
    "code": "ERR_EVALUAR_UNRESOLVED"
  },
  {
    "message": "Evaluar process is closed",
    "translation": "El Proceso en evaluar está cerrado",
    "code": "ERR_EVALUAR_PROCESS_CLOSED"
  },
  {
    "message": "Excel has duplicate rows",
    "translation": "Excel tiene filas duplicadas",
    "code": "ERR_POSTULATION_EXCEL_HAS_DUPLICATES"
  },
  {
    "message": "Profile is not active",
    "translation": "El perfil no está activo",
    "code": "ERR_PROFILE_NOT_ACTIVE"
  },
  {
    "message": "Process IGC has already registered",
    "translation": "El IGC del proceso ya se encuentra registrado",
    "code": "ERR_PROCESS_IGC_EXISTS"
  },
  {
    "message": "Process start date cannot be in the past",
    "translation": "La fecha de inicio del proceso no puede estar en el pasado",
    "code": "ERR_PROCESS_START_DATE_IN_THE_PAST"
  },
  {
    "message": "Process end date cannot be in the past",
    "translation": "La fecha de fin del proceso no puede estar en el pasado",
    "code": "ERR_PROCESS_END_DATE_IN_THE_PAST"
  },
  {
    "message": "Process start date training cannot be in the past",
    "translation": "La fecha de inicio de capacitación del proceso no puede estar en el pasado",
    "code": "ERR_PROCESS_START_DATE_TRAINING_IN_THE_PAST"
  },
  {
    "message": "Process start date cannot be greater than end date",
    "translation": "La fecha de inicio del proceso no puede ser mayor a la fecha de fin",
    "code": "ERR_PROCESS_START_DATE_GREATER_THAN_END_DATE"
  },
  {
    "message": "Recruitment reducer cannot be great than required plus room reducer",
    "translation": "El reductor de reclutamiento no puede ser mayor al requerido más el reductor de sala",
    "code": "ERR_RECRUITMENT_REDUCER_GREATER_THAN_REQUIRED_AND_ROOM_REDUCER"
  },
  {
    "message": "Process status not allowed",
    "translation": "Estado del proceso no permitido",
    "code": "ERR_PROCESS_STATUS_NOT_ALLOWED"
  },
  {
    "message": "Process not allowed",
    "translation": "Proceso no permitido",
    "code": "ERR_PROCESS_NOT_ALLOWED"
  },
  {
    "message": "Process cannot be reactivated",
    "translation": "El proceso no puede ser reactivado",
    "code": "ERR_PROCESS_CANNOT_BE_REACTIVATED"
  },
  {
    "message": "Process campus not found",
    "translation": "Campus del proceso no encontrado",
    "code": "ERR_PROCESS_CAMPUS_NOT_FOUND"
  },
  {
    "message": "Process job type not found",
    "translation": "Tipo de trabajo del proceso no encontrado",
    "code": "ERR_PROCESS_JOB_TYPE_NOT_FOUND"
  },
  {
    "message": "Process modality not found",
    "translation": "Modalidad del proceso no encontrada",
    "code": "ERR_PROCESS_MODALITY_NOT_FOUND"
  },
  {
    "message": "Process reason not found",
    "translation": "Motivo del proceso no encontrado",
    "code": "ERR_PROCESS_REASON_NOT_FOUND"
  },
  {
    "message": "Process social reason not found",
    "translation": "Motivo social del proceso no encontrado",
    "code": "ERR_PROCESS_SOCIAL_REASON_NOT_FOUND"
  },
  {
    "message": "Process status not found",
    "translation": "Estado del proceso no encontrado",
    "code": "ERR_PROCESS_STATUS_NOT_FOUND"
  },
  {
    "message": "Process trainer not found",
    "translation": "Entrenador del proceso no encontrado",
    "code": "ERR_PROCESS_TRAINER_NOT_FOUND"
  },
  {
    "message": "Process turn not found",
    "translation": "Turno del proceso no encontrado",
    "code": "ERR_PROCESS_TURN_NOT_FOUND"
  },
  {
    "message": "Vacancy already exists with this igc",
    "translation": "La vacante ya existe con este IGC",
    "code": "ERR_VACANCY_ALREADY_EXISTS_WITH_IGC"
  },
  {
    "message": "Vacancy status not allowed",
    "translation": "Estado de la vacante no permitido",
    "code": "ERR_VACANCY_STATUS_NOT_ALLOWED"
  },
  {
    "message": "Vacancies not allowed",
    "translation": "Vacantes no permitidas",
    "code": "ERR_VACANCY_NOT_ALLOWED"
  },
  {
    "message": "Vacancy not found",
    "translation": "Vacante no encontrada",
    "code": "ERR_VACANCY_NOT_FOUND"
  },
  {
    "message": "Vacancy category not found",
    "translation": "Categoría de vacante no encontrada",
    "code": "ERR_VACANCY_CATEGORY_NOT_FOUND"
  },
  {
    "message": "Vacancy status not found",
    "translation": "Estado de vacante no encontrado",
    "code": "ERR_VACANCY_STATUS_NOT_FOUND"
  },
  {
    "message": "Account already exists",
    "translation": "La cuenta ya existe",
    "code": "ERR_ACCOUNT_ALREADY_EXISTS"
  },
  {
    "message": "Account not found",
    "translation": "Cuenta no encontrada",
    "code": "ERR_ACCOUNT_NOT_FOUND"
  },
  {
    "message": "Error syncing profiles actives",
    "translation": "Error sincronizando perfiles activos",
    "code": "ERR_SYNC_PROFILES_ACTIVES"
  },
  {
    "message": "Error syncing profiles inactives",
    "translation": "Error sincronizando perfiles inactivos",
    "code": "ERR_SYNC_PROFILES_INACTIVES"
  },
  {
    "message": "Profile status not allowed",
    "translation": "Estado de perfil no permitido",
    "code": "ERR_PROFILE_STATUS_NOT_ALLOWED"
  },
  {
    "message": "Profile not found",
    "translation": "Perfil no encontrado",
    "code": "ERR_PROFILE_NOT_FOUND"
  },
  {
    "message": "Profile status not found",
    "translation": "Estado de perfil no encontrado",
    "code": "ERR_PROFILE_STATUS_NOT_FOUND"
  },
  {
    "message": "Service already exists in this account",
    "translation": "El servicio ya existe en esta cuenta",
    "code": "ERR_SERVICE_ALREADY_EXISTS_IN_ACCOUNT"
  },
  {
    "message": "Service has created processes",
    "translation": "El servicio ha creado procesos",
    "code": "ERR_SERVICE_HAS_CREATED_PROCESSES"
  },
  {
    "message": "Service not found",
    "translation": "Servicio no encontrado",
    "code": "ERR_SERVICE_NOT_FOUND"
  },
  {
    "message": "File size exceeds limit",
    "translation": "El tamaño del archivo excede el límite",
    "code": "ERR_FILE_SIZE_EXCEEDS"
  },
  {
    "message": "File count exceeds limit",
    "translation": "La cantidad de archivos excede el límite",
    "code": "ERR_FILE_COUNT_EXCEEDS"
  },
  {
    "message": "Invalid access token",
    "translation": "Token de acceso inválido",
    "code": "ERR_INVALID_ACCESS_TOKEN"
  },
  {
    "message": "Provided role does not have sufficient permissions",
    "translation": "El rol proporcionado no tiene permisos suficientes",
    "code": "ERR_ROLE_UNAUTHORIZED"
  },
  {
    "message": "Provided scope does not have sufficient permissions",
    "translation": "El alcance proporcionado no tiene permisos suficientes",
    "code": "ERR_SCOPE_UNAUTHORIZED"
  },
  {
    "message": "Invalid period",
    "translation": "Período no válido",
    "code": "ERR_INVALID_PERIOD"
  },
  {
    "message": "User is not active",
    "translation": "El usuario no está activo",
    "code": "ERR_USER_NOT_ACTIVE"
  },
  {
    "message": "Invalid new password",
    "translation": "Contraseña no válida",
    "code": "ERR_AUTH_PASSWORD_INVALID"
  },
  {
    "message": "User invalid",
    "translation": "Usuario no válido",
    "code": "ERR_USER_INVALID"
  },
  {
    "message": "Invalid recaptcha",
    "translation": "Recaptcha no válido",
    "code": "ERR_RECAPTCHA_INVALID"
  },
  {
    "message": "UserCredential not found",
    "translation": "Credencial de usuario no encontrada",
    "code": "ERR_USER_CREDENTIAL_NOT_FOUND"
  },
  {
    "message": "Role is mandatory",
    "translation": "El Rol es obligatorio",
    "code": "ERR_USER_ROLE_MANDATORY"
  },
  {
    "message": "SuperiorDocumentNumber is mandatory",
    "translation": "El Documento de Identidad del Superior es obligatorio",
    "code": "ERR_USER_SUPERIOR_DOCUMENT_NUMBER_MANDATORY"
  },
  {
    "message": "Superior not found",
    "translation": "Superior no encontrado",
    "code": "ERR_USER_SUPERIOR_NOT_FOUND"
  },
  {
    "message": "User document has already registered",
    "translation": "El documento del usuario ya se encuentra registrado",
    "code": "ERR_USER_DOCUMENT_REGISTERED"
  },
  {
    "message": "Superior not active",
    "translation": "Superior no activo",
    "code": "ERR_USER_SUPERIOR_NOT_ACTIVE"
  },
  {
    "message": "User status not allowed",
    "translation": "Estado de usuario no permitido",
    "code": "ERR_USER_STATUS_NOT_ALLOWED"
  },
  {
    "message": "User not allowed to update superior",
    "translation": "Usuario no permitido para actualizar superior",
    "code": "ERR_USER_NOT_ALLOWED_TO_UPDATE_SUPERIOR"
  },
  {
    "message": "User not found",
    "translation": "Usuario no encontrado",
    "code": "ERR_USER_NOT_FOUND"
  },
  {
    "message": "User roles not found",
    "translation": "Roles de usuario no encontrados",
    "code": "ERR_USER_ROLE_NOT_FOUND"
  },
  {
    "message": "User status not found",
    "translation": "Estado de usuario no encontrado",
    "code": "ERR_USER_STATUS_NOT_FOUND"
  },
  {
    "message": "Current user is not available to do this action for the role supplied",
    "translation": "El usuario actual no está disponible para realizar esta acción para el rol suministrado",
    "code": "ERR_USER_UNSUPPORTED_ROLE"
  },
  {
    "message": "User does not have the COORDINATOR role",
    "translation": "El usuario no tiene el rol COORDINATOR",
    "code": "ERR_USER_NOT_COORDINATOR"
  },
  {
    "message": "User already has a workload",
    "translation": "El usuario ya tiene una carga laboral",
    "code": "ERR_USER_ALREADY_HAS_WORKLOAD"
  },
  {
    "message": "User to add is not active",
    "translation": "El usuario a agregar no está activo",
    "code": "ERR_WORKLOAD_MAX_DEPENDENTS_REACHED"
  },
  {
    "message": "User does not have the analyst role",
    "translation": "El usuario no tiene el rol analista",
    "code": "ERR_USER_NOT_ANALYST"
  },
  {
    "message": "Workload must have at least one dependent",
    "translation": "La carga laboral debe tener al menos un dependiente",
    "code": "ERR_WORKLOAD_AT_LEAST_ONE_DEPENDENT"
  },
  {
    "message": "Maximum number of igcs reached",
    "translation": "Se alcanzó el número máximo de igcs",
    "code": "ERR_WORKLOAD_MAX_IGCS_REACHED"
  },
  {
    "message": "Vacancy to add is not active",
    "translation": "La vacante a agregar no está activa",
    "code": "ERR_VACANCY_NOT_ACTIVE"
  },
  {
    "message": "Vacancy to add is not created by the user",
    "translation": "La vacante a agregar no fue creada por el usuario",
    "code": "ERR_VACANCY_NOT_CREATED_BY_CURRENT_USER"
  },
  {
    "message": "Workload must have at least one igc",
    "translation": "La carga laboral debe tener al menos un igc",
    "code": "ERR_WORKLOAD_AT_LEAST_ONE_IGC"
  },
  {
    "message": "Analyst already exists in workload",
    "translation": "El analista ya existe en la carga laboral",
    "code": "ERR_ANALYST_ALREADY_EXISTS_IN_WORKLOAD"
  },
  {
    "message": "Analyst not found in workload",
    "translation": "Analista no encontrado en la carga laboral",
    "code": "ERR_ANALYST_NOT_FOUND_IN_WORKLOAD"
  },
  {
    "message": "Igc already exists in workload",
    "translation": "El igc ya existe en la carga laboral",
    "code": "ERR_IGC_ALREADY_EXISTS_IN_WORKLOAD"
  },
  {
    "message": "Igc not found in workload",
    "translation": "Igc no encontrado en la carga laboral",
    "code": "ERR_IGC_NOT_FOUND_IN_WORKLOAD"
  },
  {
    "message": "User not found",
    "translation": "Usuario no encontrado",
    "code": "ERR_USER_UNRESOLVED"
  },
  {
    "message": "Profile does not have any competence",
    "translation": "El perfil no tiene ninguna competencia",
    "code": "ERR_PROFILE_WITHOUT_COMPETENCE",
  },
  {
    "message": "The candidate has incomplete data",
    "translation": "El candidato tiene sus datos incompletos",
    "code": "ERR_POSTULATION_CANDIDATE_DATA_INCOMPLETE",
  },
  {
    "code": "ERR_POSTULATION_TEST_NOT_FINISHED",
    "message": "The candidate did not finish his tests",
    "translation": "El candidato no finalizó sus pruebas",
  },
  {
    "code": "ERR_PROCESS_CLOSED_IN_EVALUAR",
    "message": "The process is closed in evaluar",
    "translation": "El proceso está cerrado en evaluar",
  },
  {
    "code": "ERR_INVALID_FILTER",
    "message": "Invalid filter",
    "translation": "Filtro no válido",
  },
  {
    "code": "ERR_INVALID_VALUE",
    "message": "Invalid value",
    "translation": "Valor no válido",
  }
];

export default errorsCodes;
