<!-- eslint-disable no-unused-expressions -->
<template>
  <v-flex xs12 md6 xl3 pa-2 pt-5 pt-md-12 style="width: 100%">
    <!-- Progress Linear -->
    <div class="d-flex align-content-space-around">
      <v-progress-linear :value="progressPersonalData" color="teal" height="20" style="border-radius: 100px"
        v-if="nextStep !== 'step5'">
        <template v-slot="{ value }">
          <strong class="white--text body-2" v-if="progressPersonalData > 0">{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
      <v-progress-linear :value="progressProfessionalData" class="ml-4" color="teal" height="20"
        style="border-radius: 100px" reactive v-if="nextStep !== 'step5'">
        <template v-slot="{ value }">
          <strong class="white--text body-2" v-if="progressProfessionalData > 0">{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
      <v-progress-linear v-if="nextStep !== 'step5'" :value="progressExperienceData" class="ml-4" color="teal"
        height="20" style="border-radius: 100px" reactive>
        <template v-slot="{ value }">
          <strong class="white--text body-2" v-if="progressExperienceData > 0">{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
      <v-progress-linear v-if="nextStep !== 'step5'" :value="progressPreferencesData" class="ml-4" color="teal"
        height="20" style="border-radius: 100px" reactive>
        <template v-slot="{ value }">
          <strong class="white--text body-2" v-if="progressPreferencesData > 0">{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </div>
    <!-- STEP 1: PERSONAL DATA -->
    <div class="justify-center text-center" style="margin-bottom: 70px">
      <div v-if="nextStep === 'step1'">
        <PersonalDataStep :countPersonalData="countPersonalData" :addPersonalData="addPersonalData"
          v-on:markerCoordinates="getCoordinates($event)" v-on:initialCoordinates="getCoordinatesInitial($event)"
          v-on:addMarker="getDirection($event)">
        </PersonalDataStep>
      </div>
      <div v-if="
        progressPersonalData == 100 &&
        nextStep === 'step2'
      ">
        <ProfessionalDataStep :countProfessionalData="countProfessionalData" :addProfessionalData="addProfessionalData"
          v-on:markerCoordinates="getCoordinatesStudy($event)" v-on:addMarker="getDirectionStudy($event)"
          :addPersonalData="addPersonalData"></ProfessionalDataStep>
      </div>
      <div v-if="nextStep === 'step3'">
        <ExperienceDataStep :countExperienceData="countExperienceData" :addExperienceData="addExperienceData"
          :addPersonalData="addPersonalData" :addExp="addExp" :allDataExperience="allDataExperience" />
      </div>
      <div v-if="nextStep === 'step4'">
        <PreferencesDataStep :countPreferencesData="countPreferencesData" :addPersonalData="addPersonalData"
          :addPreferencesData="addPreferencesData">
        </PreferencesDataStep>
      </div>
      <div v-if="nextStep === 'step5'">
        <div v-if="!registrationFailed">
          <p v-if="addPersonalData.nombre_social_intersexual !== ''"
            class="text-center black--text font-weight-bold subtitle-1 mb-0 mx-2">
            {{ addPersonalData.nombre_social_intersexual }},
            ¡ya te encuentras en el proceso de evaluación!
          </p>
          <p v-else class="text-center black--text font-weight-bold subtitle-1 mb-3 mx-2">
            {{ addPersonalData.nombres }}, ¡Ya te encuentras en el proceso de evaluación!
          </p>
          <p style="font-size: 17px;">
            {{ message }}
            <br>
            <v-btn color="#FA7048" class="white--text text-center" style="margin-top: 1rem"
              @click="handleGoToEvaluar">REALIZAR MIS PRUEBAS</v-btn>
          </p>
          <v-img alt="felicitación" class="mt-1 text-center mx-auto" contain src="../../../assets/celebrate.gif"
            max-width="350px" />
          <v-divider class="my-teal-divider"></v-divider>
          <p class="text-center black--text font-weight-bold subtitle-2 mt-5 mx-2">
            Aquí coméntanos qué te pareció la experiencia de este proceso
          </p>
          <div class="text-center teal--text">
            <v-rating v-model="rating" :readonly="isCommented === true" color="#FDD835"
              v-on:input="buttonFeedbackStatus()"></v-rating>
          </div>
          <p class="text-center teal--text" v-if="isCommented === true">¡Gracias por tu respuesta!</p>
          <div v-else>
            <v-text-field v-if="rating !== null" outlined v-model="ratingComment" placeholder="Escribe tu comentario"
              label="Comentario" id="id" class="pa-0 mt-3 grey-label"></v-text-field>
            <div class="d-flex justify-center">
              <v-btn width="250" height="45" :disabled="rating === null || ratingComment === ''"
                :loading="isLoadingSend" color="teal" @click="sendFeedback()" class="white--text mt-6">
                Enviar
              </v-btn>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="text-center black--text font-weight-bold subtitle-1 mb-3 mx-2">
            Lo sentimos, ocurrió un problema al registrar tus datos.
          </p>
          <v-img alt="felicitación" class="mt-1 text-center mx-auto" contain src="../../../assets/celebrate.gif"
            max-width="350px" />
          <p style="font-size: 17px;">
            Estamos trabajando para solucionarlo. Por favor, inténtalo más tarde.
          </p>
        </div>
      </div>
    </div>
    <AlertComponent :show.sync="alert.show" :message="alert.message" :type="alert.type" />
    <!-- Control Buttons -->
    <v-row v-if="nextStep !== 'step5'">
      <v-col class="text-start">
        <v-btn color="teal" v-show="hidden" class="white--text" fab absolute dark bottom left @click="btnPrevStep()">
          <v-icon class="invert-icon">mdi-play</v-icon>
        </v-btn>
      </v-col>
      <v-col class="text-end">
        <v-btn v-if="countPreferencesData !== 3
          && (addPreferencesData.modality === ''
            || addPreferencesData.modality === 'Trabajo remoto')" fab
          :disabled="!addPersonalData.datosValidPer && progressPersonalData !== 0" color="teal" class="white--text"
          absolute bottom right @click="btnNextStep()">
          <v-icon>mdi-play</v-icon>
        </v-btn>
        <div class="d-flex justify-center ">
          <v-btn v-if="countPreferencesData === 3
            || addPreferencesData.modality === 'Presencial'" color="teal" class="white--text mb-3" :loading="isLoading"
            :disabled="addPersonalData.datosValidPer === false" absolute bottom right @click="registerPostulant()">
            Finalizar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-flex>
</template>

<script>

import dayjs from 'dayjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { saveFeedbackUser, createPostulant } from '../../../services/dynamo/postulants';
import { getAuthToken } from '../../../services/auth/auth';

import { handleError } from '../../../utils/handleError';

export default {
  name: 'StepForm',
  props: {
    permissionShareData: Boolean,
    welcomeData: Object,
  },
  components: {
    PersonalDataStep: () => import('./personal_data_step.vue'),
    ProfessionalDataStep: () => import('./professional_data_step.vue'),
    ExperienceDataStep: () => import('./experience_data_step.vue'),
    PreferencesDataStep: () => import('./preferences_data.step.vue'),
    AlertComponent: () => import('../../Notification.vue'),
  },
  data() {
    return {
      message: 'Te hemos asignado y enviado a tu celular unas pruebas, las cuales debes realizar para poder continuar con tu evaluación:',
      progressPersonalData: 0,
      progressProfessionalData: 0,
      progressExperienceData: 0,
      progressRotationData: 0,
      countPersonalData: 0,
      countProfessionalData: 0,
      countExperienceData: 0,
      countRotationData: 0,
      countPreferencesData: 0,
      progressPreferencesData: 0,
      isLoading: false,
      hidden: false,
      nextStep: 'step1',
      addExperience: false,
      addPersonalData: {
        datosValidPer: true,
        nombres: '',
        apellido_p: '',
        apellido_m: '',
        nombre_social: '',
        nombre_social_intersexual: '',
        estado_civil: 'Soltero(a)',
        fecha_nac: null,
        genero: '',
        correo: '',
        telefono: null,
        telefonoReferencial: null,
        n_hijos: 0,
        text_dir: '',
        como_konecta: null,
        documentTypeColaborador: '',
        nroDoc_colaborador: '',
        misma_cuenta: '',
        provincia: '',
        distrito: '',
      },
      addProfessionalData: {
        grado_formacion: null,
        institucion: '',
        estado_estudios: '',
      },
      addExperienceData: {
        flag_se: '',
        typeOfExperience: [],
        areasOfExperiencieCall: [],
        work_konecta: '',
        customerSupport: {
          nameEnterprise: '',
          clientCategory: '',
          yearsExperience: '',
          monthExperience: '',
          segment: 'Atención al cliente',
        },
        backOffice: {
          nameEnterprise: '',
          clientCategory: '',
          yearsExperience: '',
          monthExperience: '',
          segment: 'Back Office',
        },
        crosseling: {
          nameEnterprise: '',
          clientCategory: '',
          yearsExperience: '',
          monthExperience: '',
          segment: 'Crosseling',
        },
        socialNetworks: {
          nameEnterprise: '',
          clientCategory: '',
          yearsExperience: '',
          monthExperience: '',
          segment: 'Redes Sociales',
        },
        sales: {
          nameEnterprise: '',
          clientCategory: '',
          yearsExperience: '',
          monthExperience: '',
          segment: 'Ventas',
        },
        others: {
          nameEnterprise: '',
          clientCategory: '',
          yearsExperience: '',
          monthExperience: '',
          segment: 'Otros',
        },
        otherRubro: {
          nameEnterprise: '',
          clientCategory: '',
          yearsExperience: '',
          monthExperience: '',
          position: '',
        },
      },
      addPreferencesData: {
        range: '',
        district: [],
        service: [],
        internet: '',
        megas: '',
        headphones: '',
        device: '',
        ram: '',
        processor: '',
        modality: '',
      },
      family: [],
      nextStep2: 0,
      nextStep3: 0,
      nextStep4: 0,
      markersPersonal: {},
      addressTextPersonal: '',
      allDataExperience: [],
      rating: null,
      ratingComment: '',
      isCommented: false,
      isLoadingSend: false,
      alert: {
        show: false,
        message: '',
        type: 'info'
      },
      registrationFailed: false,
    };
  },
  created() {
    this.$gtag.event('LP_btn_InicioSección1', {
      event_category: 'LP_btn_InicioSección1',
      event_label: 'LP_btn_InicioSección1',
      value: 1,
    });
  },
  methods: {
    showAlert(message, type) {
      this.alert = {
        show: true,
        message: message,
        type: type
      };
    },
    handleGoToEvaluar() {
      const linkEvaluar = sessionStorage.getItem('link-postulation');
      window.open(linkEvaluar, '_blank');
      // 71741046
      this.$router.push('/empleos');
    },
    generatePayload(dataToParse) {
      const payload = {
        igc: dataToParse?.igc,
        names: dataToParse?.nombres,
        fatherLastname: dataToParse?.apellido_p,
        motherLastname: dataToParse?.apellido_m,
        documentType: dataToParse?.tipodoc,
        documentNumber: dataToParse?.numdoc,
        cellphone: dataToParse?.telefono,
        email: dataToParse?.correo,
        gender: dataToParse?.genero,
        birthDate: dataToParse?.fecha_nac,
        nationality: dataToParse?.nacionalidad,
        civilStatus: dataToParse?.estado_civil,
        referenceCellphone: dataToParse?.telefonoReferencial,
        directionText: dataToParse?.text_dir,
        referenceDirection: null,
        howKnowUs: dataToParse?.como_konecta,
        socialName: dataToParse?.nombre_social,
        applicationOrigin: dataToParse?.origen_postulacion,
        children: dataToParse?.n_hijos || 0,
        datosExperiencia: dataToParse?.DatosExperiencia,
        datosFamiliares: dataToParse?.DatosFamiliares,
        datosProfesionales: dataToParse?.DatosProfesionales,
        datosRotacion: dataToParse?.DatosRotacion,
        especificacionTecnica: dataToParse?.EspecificacionTecnica,
        equipos: dataToParse?.dispositivo,
      };

      return payload;
    },
    btnPrevStep() {
      if (this.countPersonalData < 6 && this.nextStep === 'step1') {
        this.countPersonalData -= 1;
        this.progressPersonalData -= 17;
      }
      if (
        this.progressPersonalData === 100
        && this.countPersonalData === 5
      ) {
        this.countPersonalData -= 1;
        this.progressPersonalData -= 15;
      }
      if (this.countPersonalData === 0 || this.countPersonalData === 100) {
        this.hidden = false;
      } else {
        this.hidden = true;
      }

      if (this.nextStep === 'step3') {
        if (this.countExperienceData === 2
          && this.addExperienceData.typeOfExperience.length === 1) {
          this.progressExperienceData -= 50;
        }
        if (this.countExperienceData === 3
          && this.addExperienceData.typeOfExperience.length === 1) {
          this.progressExperienceData -= 50;
          this.countExperienceData -= 1;
        }
        if (this.countExperienceData === 2
          && this.addExperienceData.typeOfExperience.length === 2) {
          this.progressExperienceData -= 30;
        }
        if (this.countExperienceData === 3
          && this.addExperienceData.typeOfExperience.length === 2) {
          this.progressExperienceData -= 35;
        }
        this.countExperienceData -= 1;
        // eslint-disable-next-line no-unused-expressions
        this.countExperienceData === 1 ? (this.hidden = false) : (this.hidden = true);
      }

      if (this.nextStep === 'step4') {
        this.countPreferencesData -= 1;
        this.progressPreferencesData -= 50;
        // eslint-disable-next-line no-unused-expressions
        this.countPreferencesData === 1 || this.progressPreferencesData === 100
          ? (this.hidden = false)
          : (this.hidden = true);
      }
    },
    btnNextStep() {
      if (
        this.progressPersonalData === 85
        && this.countPersonalData === 5
      ) {
        this.countPersonalData += 1;
        this.progressPersonalData += 15;
      }
      if (
        this.progressPersonalData !== 100
        && this.countPersonalData <= 4
      ) {
        this.hidden = true;
        this.countPersonalData += 1;
        this.progressPersonalData += 17;
      }
      if (this.progressPersonalData === 100) {
        this.$gtag.event('LP_btn_FinSección1', {
          event_category: 'LP_btn_FinSección1',
          event_label: 'LP_btn_FinSección1',
          value: 1,
        });
        this.progressPersonalData += 0;
        this.hidden = false;
        this.nextStep2 += 1;
      }
      if (this.nextStep2 === 2) {
        this.nextStep = 'step2';
        this.$gtag.event('LP_btn_InicioSección2', {
          event_category: 'LP_btn_InicioSección2',
          event_label: 'LP_btn_InicioSección2',
          value: 1,
        });
      }

      if (this.nextStep === 'step2' && this.progressProfessionalData === 0 && this.countProfessionalData === 1) {
        this.countProfessionalData += 1;
        this.progressProfessionalData += 100;
      }

      if (this.nextStep === 'step2' && this.progressProfessionalData === 0 && this.countProfessionalData === 0) {
        this.countProfessionalData += 1;
      }

      if (this.countProfessionalData === 2 && this.progressProfessionalData === 100) {
        this.progressProfessionalData += 0;
        this.hidden = false;
        this.nextStep3 += 1;
        this.$gtag.event('LP_btn_FinSección2', {
          event_category: 'LP_btn_FinSección2',
          event_label: 'LP_btn_FinSección2',
          value: 1,
        });
      }

      if (this.nextStep3 === 2) {
        this.nextStep = 'step3';
        this.$gtag.event('LP_btn_InicioSección3', {
          event_category: 'LP_btn_InicioSección3',
          event_label: 'LP_btn_InicioSección3',
          value: 1,
        });
      }
      if (this.nextStep === 'step3' && this.addExperienceData.flag_se === '1') {
        this.hidden = false;
        this.progressExperienceData = 100;
        this.countExperienceData = 4;
      }
      if (this.nextStep === 'step3' && this.addExperienceData.flag_se !== '1' && this.countExperienceData >= 1 && this.addExperienceData.typeOfExperience.length === 1 && this.progressExperienceData !== 100) {
        this.hidden = true;
        // eslint-disable-next-line no-unused-expressions
        if (this.addExperienceData.typeOfExperience[0] === 'call' && this.progressExperienceData === 0) {
          this.countExperienceData += 1;
          this.progressExperienceData += 50;
        } else {
          if (this.addExperienceData.typeOfExperience[0] === 'otro' && this.progressExperienceData === 50) {
            this.countExperienceData += 1;
          } else {
            this.countExperienceData += 2;
          }
          this.progressExperienceData += 50;
        }
      }
      if (this.nextStep === 'step3'
        && this.countExperienceData >= 1
        && this.addExperienceData.typeOfExperience.length === 2
        && this.progressExperienceData !== 100
      ) {
        if (this.countExperienceData === 1) {
          this.hidden = true;
          this.countExperienceData += 1;
          this.progressExperienceData = 30;
        } else {
          this.hidden = true;
          this.countExperienceData += 1;
          this.progressExperienceData += 35;
        }
      }
      if (this.nextStep === 'step3' && this.progressExperienceData === 0 && this.countExperienceData === 0) {
        this.countExperienceData += 1;
      }
      if (this.countExperienceData === 4 && this.progressExperienceData === 100) {
        this.progressExperienceData += 0;
        this.hidden = false;
        this.nextStep4 += 1;
        this.$gtag.event('LP_btn_FinSección3', {
          event_category: 'LP_btn_FinSección3',
          event_label: 'LP_btn_FinSección3',
          value: 1,
        });
      }

      if (this.nextStep4 === 2) {
        this.nextStep = 'step4';
        this.$gtag.event('LP_btn_InicioSección4', {
          event_category: 'LP_btn_InicioSección4',
          event_label: 'LP_btn_InicioSección4',
          value: 1,
        });
      }
      if (this.nextStep === 'step4' && this.countPreferencesData !== 0) {
        if (this.addPreferencesData.modality === 'Presencial') {
          this.countPreferencesData += 2;
          this.progressPreferencesData += 100;
        } else {
          this.countPreferencesData += 1;
          this.progressPreferencesData += 50;
          this.hidden = true;
        }
      }
      if (this.nextStep === 'step4' && this.countPreferencesData === 0) {
        this.countPreferencesData += 1;
        this.hidden = false;
      }
      this.addPersonalData.datosValidPer = true;
    },
    getCoordinates(getCoordinates) {
      this.markersPersonal = getCoordinates;
    },
    getCoordinatesInitial(getCoordinatesInitial) {
      this.initialCoordinates = getCoordinatesInitial;
    },
    getDirection(getDirection) {
      this.addressTextPersonal = getDirection;
    },
    getCoordinatesStudy(getCoordinatesStudy) {
      this.markersStudy = getCoordinatesStudy;
    },
    getDirectionStudy(getDirectionStudy) {
      this.addressTextStudy = getDirectionStudy;
    },
    setDisabledExperience() {
      this.disabledExperience = true;
    },
    addExp() {
      this.setDisabledExperience();
      if (this.countBtnAddExp < 2) {
        this.countExpLab = 0;
        this.añadirExp = true;
        this.countBtnAddExp += 1;
      }
    },
    async getRecaptchaToken() {
      try {
        await this.$recaptchaLoaded();
        const recaptchaToken = await this.$recaptcha('login');
        return recaptchaToken;
      } catch (error) {
        this.showAlert('Ocurrió un error al obtener el token de recaptcha', 'error');
        console.error(error);
      }
    },
    analyticaFinPostulacion() {
      this.$gtag.event('LP_btn_FinPostulación', {
        event_category: 'LP_btn_FinPostulación',
        event_label: 'LP_btn_FinPostulación',
        value: 1,
      });
    },
    // 71741046 aqui esta el codigo recuperado
    async handleSavePostulant(dataForRegister) {
      try {
        const result = await createPostulant(dataForRegister);
        const link = result?.data?.testLink;
        sessionStorage.setItem('link-postulation', link);
      } catch (error) {
        const errorMessage = handleError(error);
        console.log(errorMessage, ' ERROR ');
        console.error(errorMessage, ' ERROR ');
        this.showAlert(errorMessage, 'error');
        console.error(error);
        this.registrationFailed = true;
      }
    },
    async registerPostulant() {
      try {
        this.isLoading = true;
        const postulantId = uuidv4();
        const registerDate = {
          date: dayjs().format('DD/MM/YYYY'),
          hour: dayjs().format('HH:mm'),
          milliseconds: dayjs().valueOf(),
        };
        const jobId = sessionStorage.getItem('jobId');
        const districtName = this.generateDistrictName();
        const category = sessionStorage.getItem('jobCategory');
        const createdBy = sessionStorage.getItem('createdBy');
        const title = sessionStorage.getItem('title');
        const igc = sessionStorage.getItem('igc-valid');


        const schedule = this.addPreferencesData.range.split(' - ');
        const newExperienceData = this.generatePayloadExperience(this.addExperienceData);

        const dataForRegister = {
          igc: igc,
          Convocatoria: category ? { category, createdBy, title } : null,
          DatosFamiliares: [],
          DatosProfesionales: {
            coord_estudio: null,
            createdAt: dayjs().valueOf(),
            estado_estudios: this.addProfessionalData.estado_estudios,
            grado_formacion: this.addProfessionalData.grado_formacion,
            horario_estudio: null,
            id_postulante: postulantId,
            institucion: this.addProfessionalData.institucion,
            rubro_carrera: '-',
            text_dir_estudio: null,
          },
          DatosRotacion: {
            actividad_tiempo_libre: [],
            actividades: null,
            coord_actividad: ['', ''],
            createdAt: dayjs().valueOf(),
            fam_postulante: null,
            horario_actividad: null,
            id_postulante: postulantId,
            motivacion: null,
            sede_preferencia: this.addPersonalData.provincia,
            sede_preferencia_distrito: this.addPersonalData.distrito,
            sede_referencia: this.addPersonalData.provincia === 'A' ? this.addPreferencesData.district : null,
            servicio_preferencia: this.addPreferencesData.service,
            text_dir_actividad: null,
            time_preferencia: null,
            type_work: this.addPreferencesData.modality,
            horarioDisponible: {
              timeLVB: schedule[0],
              timeLVE: schedule[1],
              timeSDB: schedule[0],
              timeSDE: schedule[1],
              weekDay: 'Sábado y Domingo',
            },
            trabajo_k: this.addExperienceData.work_konecta ? 'Sí' : 'No',
          },
          EntrevistaProgramada: false,
          EspecificacionTecnica: {
            RegistradoDate: registerDate,
            auriculares: this.addPreferencesData.headphones,
            createdAt: dayjs().valueOf(),
            dispositivo: this.addPreferencesData.modality === 'Presencial' ? 'Ninguno' : this.addPreferencesData.device,
            id_postulante: postulantId,
            marcaSmartphone: [],
            megas: this.addPreferencesData.megas,
            memoria: this.addPreferencesData.ram,
            otroDispositivo: null,
            otroSmartphone: null,
            procesador: this.addPreferencesData.processor,
            servicio_internet: this.addPreferencesData.modality === 'Presencial' ? 'No' : this.addPreferencesData.internet,
          },
          RegistradoDate: registerDate,
          apellido_m: this.addPersonalData.apellido_m,
          apellido_p: this.addPersonalData.apellido_p,
          como_konecta: this.addPersonalData.como_konecta,
          convocatoria_id: jobId,
          coordenadas_direccion: ['', ''],
          correo: this.addPersonalData.correo,
          createdAt: dayjs().valueOf(),
          createdDate: dayjs().format('YYYY-MM-DD'),
          documentTypeColaborador: this.addPersonalData.como_konecta === 'Referido/a' ? this.addPersonalData.documentTypeColaborador : null,
          estado_civil: null,
          fecha_nac: this.addPersonalData.fecha_nac,
          genero: this.addPersonalData.genero,
          id: postulantId,
          misma_cuenta: this.addPersonalData.misma_cuenta,
          n_hijos: null,
          nacionalidad: null,
          nombre_social: this.addPersonalData.nombre_social !== ''
            ? this.addPersonalData.nombre_social
            : 'Sin registro',
          nombre_social_intersexual:
            this.addPersonalData.nombre_social_intersexual !== ''
              ? this.addPersonalData.nombre_social_intersexual
              : 'Sin registro',
          nombres: this.addPersonalData.nombres,
          nroDoc_colaborador: this.addPersonalData.como_konecta === 'Referido/a' ? this.addPersonalData.nroDoc_colaborador : null,
          numdoc: this.welcomeData.documentNumber,
          origen_postulacion: jobId ? 'Bolsa de trabajo' : 'Landing',
          permission_share_data: this.permissionShareData,
          privacyPolicy: true,
          telefono: this.addPersonalData.telefono,
          telefonoReferencial: this.addPersonalData.telefonoReferencial,
          text_dir:
            // eslint-disable-next-line no-nested-ternary
            this.addPersonalData.provincia === 'A' ? `,,,,${districtName},LIMA,LIMA,PERÚ`
              // eslint-disable-next-line no-nested-ternary
              : this.addPersonalData.provincia === 'Q' ? `,,,,${districtName},AREQUIPA,AREQUIPA,PERÚ`
                // eslint-disable-next-line no-nested-ternary
                : this.addPersonalData.provincia === 'P' ? `,,,,${districtName},PIURA,PIURA,PERÚ`
                  : this.addPersonalData.provincia === 'E' ? `,,,,${districtName},CHICLAYO,LAMBAYEQUE,PERÚ`
                    : `,,,,${districtName},TRUJILLO,LA LIBERTAD,PERÚ`,
          tipodoc: this.welcomeData.selectDocumentType,
        };

        if (newExperienceData.length > 0) {
          dataForRegister.DatosExperiencia = newExperienceData;
        }
        const newPayload = this.generatePayload(dataForRegister);
        await this.handleSavePostulant(newPayload);
        this.analyticaFinPostulacion();
        this.nextStep = 'step5';
      } catch (error) {
        console.log(error);
        const errorMessage = handleError(error);
        console.error(errorMessage, ' ERROR ');
        this.showAlert(errorMessage, 'error');
      } finally {
        this.isLoading = true;
      }
    },
    generateDistrictName() {
      const districtWithoutAccent = this.removeAccentMark(this.addPersonalData.distrito);
      if (districtWithoutAccent === 'ZAÑA') {
        return 'SAÑA';
      }
      if (districtWithoutAccent === 'PUERTO ETEN') {
        return 'ETEN PUERTO';
      }
      return districtWithoutAccent;
    },
    removeAccentMark(str) {
      return str.replace(/[áéíóú]/gi, (match) => {
        switch (match) {
          case 'á':
            return 'a';
          case 'é':
            return 'e';
          case 'í':
            return 'i';
          case 'ó':
            return 'o';
          case 'ú':
            return 'u';
          default:
            return '';
        }
      }).toUpperCase();
    },
    generateExperienceData(postulantId) {
      const experienceData = [];
      const isCallExperience = {
        eo_empresa: null,
        eo_puesto: null,
        eo_retribucion_basico: null,
        eo_retribucion_comisiones: null,
        eo_rubro_empresa: null,
        eo_tiempo_exp: null,
        flag_eo: null,
      };
      const withoutExperienceProperties = {
        se_expect_salarial: [0, 0],
        se_p_atc: null,
        se_p_backof: null,
        se_p_crosselling: null,
        se_p_redes: null,
        se_p_ventas: null,
      };
      const noCallExperience = {
        ec_cliente: null,
        ec_empresa: null,
        ec_retribucion_basico: null,
        ec_retribucion_comisiones: null,
        ec_rubro_cliente: null,
        ec_segmento: null,
        ec_tiempo_exp: null,
      };

      // CUANDO NO TIENE EXPERIENCIA
      if (this.addExperienceData.flag_se === '1') {
        const withoutExperienceData = {
          createdAt: dayjs().valueOf(),
          flag_ec: null,
          flag_eo: null,
          flag_se: '1',
          id: uuidv4(),
          id_postulante: postulantId,
          ...withoutExperienceProperties,
          ...isCallExperience,
          ...noCallExperience,
        };
        experienceData.push(withoutExperienceData);
      } else {
        // CUANDO TIENE EXPERIENCIA
        Object.keys(this.addExperienceData).forEach((key) => {
          // if (typeof this.addExperienceData[key] === 'object' && this.addExperienceData[key].nameEnterprise !== '' && this.addExperienceData[key].nameEnterprise !== undefined) {
          if (typeof this.addExperienceData[key] === 'object' && this.addExperienceData[key].nameEnterprise !== '' && this.addExperienceData[key].nameEnterprise !== undefined && this.addExperienceData[key].nameEnterprise !== null) {
            if (key !== 'otherRubro') {
              const expCall = {
                createdAt: dayjs().valueOf(),
                ec_cliente: '-',
                ec_empresa: this.addExperienceData[key].nameEnterprise,
                ec_retribucion_basico: null,
                ec_retribucion_comisiones: null,
                ec_rubro_cliente: this.addExperienceData[key].clientCategory,
                ec_segmento: this.addExperienceData[key].segment,
                ec_tiempo_exp: Number(this.addExperienceData[key].yearsExperience) * 12
                  + Number(this.addExperienceData[key].monthExperience),
                flag_ec: 1,
                flag_se: '0',
                id: uuidv4(),
                id_postulante: postulantId,
                ...isCallExperience,
                ...withoutExperienceProperties,
              };
              experienceData.push(expCall);
            } else {
              const expNoCall = {
                createdAt: dayjs().valueOf(),
                eo_empresa: this.addExperienceData[key].nameEnterprise,
                eo_puesto: this.addExperienceData[key].position,
                eo_retribucion_basico: null,
                eo_retribucion_comisiones: null,
                eo_rubro_empresa: this.addExperienceData[key].clientCategory,
                eo_tiempo_exp: Number(this.addExperienceData[key].yearsExperience) * 12
                  + Number(this.addExperienceData[key].monthExperience),
                flag_eo: 1,
                flag_ec: null,
                flag_se: '0',
                id: uuidv4(),
                id_postulante: postulantId,
                ...noCallExperience,
                ...withoutExperienceProperties,
              };
              experienceData.push(expNoCall);
            }
          }
        });
      }
      return experienceData;
    },
    generatePayloadExperience(experienceData) {
      const experienceArray = Object.entries(experienceData);

      const payload = experienceArray.map(([key, data]) => {
        let tipoExperiencia = '';
        let empresa = '';
        let cliente = '-';
        let segmento = '';
        let rubro = '';
        let tiempoExperiencia = 0;

        if (['customerSupport', 'backOffice', 'crosseling', 'socialNetworks', 'sales', 'others'].includes(key)) {
          if (experienceData.areasOfExperiencieCall.includes(data.segment)) {
            tipoExperiencia = 'Call Center';
            segmento = data.segment || '';
          } else {
            return;
          }
        } else {
          tipoExperiencia = 'Otros';
          segmento = data.position || '';
        }

        empresa = data.nameEnterprise || '';
        rubro = data.clientCategory || '';
        tiempoExperiencia = (Number(data.yearsExperience) || 0) * 12 + (Number(data.monthExperience) || 0);

        if (empresa && segmento && !isNaN(tiempoExperiencia)) {
          return {
            tipoExperiencia,
            empresa,
            cliente,
            segmento,
            rubro,
            tiempoExperiencia,
          };
        }
      }).filter(Boolean);
      return payload;
    },
    analyticEnviarCalificacion() {
      this.$gtag.event('LP_btn_EnviarCalificación', {
        event_category: 'LP_btn_EnviarCalificación',
        event_label: 'LP_btn_EnviarCalificación',
        value: 1,
      });
    },
    async sendFeedback() {
      try {
        this.isLoadingSend = true;
        const recaptchaToken = await this.getRecaptchaToken();
        const token = await getAuthToken(this.welcomeData.documentNumber, recaptchaToken);
        const recaptchaTokenForFeedback = await this.getRecaptchaToken();
        const feedbackData = {
          numDoc: this.welcomeData.documentNumber,
          rating: this.rating,
          comment: this.ratingComment,
          createdAt: dayjs().valueOf(),
          date: dayjs().format('DD/MM/YYYY'),
          id: uuidv4(),
        };
        await saveFeedbackUser(feedbackData, token, recaptchaTokenForFeedback);
        this.analyticEnviarCalificacion();
        this.isCommented = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingSend = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
  bottom: 20px !important;
}

.invert-icon {
  transform: rotate(180deg) scaleX(1);
}

.text-link {
  color: rgba(51, 102, 255, 1) !important;
  cursor: pointer;
  font-size: 15px;
}

.theme--light.v-divider {
  border-color: teal;
}
</style>
